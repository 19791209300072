<template>
  <div class="base-demo" style="width: auto">
    <vue-table-dynamic
        :params="params"
        @cell-change="onCellChange"
        @select="onSelect"
        @selection-change="onSelectionChange"
        ref="table"
    ></vue-table-dynamic>
    <input type="button" value="Neu" @click="onnewClick">
  </div>
</template>

<script>

import VueTableDynamic from 'vue-table-dynamic'

export default {
  name: 'mTable',
  components: {
    VueTableDynamic
  },
  data() {
    return {
      editableCols:[],
      params: {
        data: [],
        header: 'row',
        border: true,
        sort: [0, 1],
        stripe: false,
        showCheck: true,
        enableSearch: true,
        highlight: {},
        highlightedColor: 'rgb(197, 209, 227)',
        edit: {
          row: [1],
          column: [1],
          cell: [[-1, -1]]
        }
      }
    }
  },
  methods: {
    onnewClick () {
      this.$http.post('https://grid.cl-serv.de/index.php?action=INSERT', {text1: "ID"}).then(response => {
        console.log(response)
        this.changeStatus()
      }, response => {
        // error callback
        console.log(response)
      });
      console.log("Click")
    },
    onCellChange (rowIndex, columnIndex, data) {
      console.log('onCellChange: ', rowIndex, columnIndex, data)
      console.log('table data: ', this.$refs.table.getData()[rowIndex])

      var headData = this.$refs.table.getData()[0]
      var rowData = this.$refs.table.getData()[rowIndex]
      var jSO = {};

      var i;
      for (i = 0; i < headData.length; i++) {
        jSO[headData[i]] = rowData[i]
      }

      console.log('jSO: ', jSO)

      this.$http.post('https://grid.cl-serv.de/index.php?action=UPDATE', jSO).then(response => {
        console.log(response)
      }, response => {
        // error callback
        console.log(response)
      });
    },
    onSelect (isChecked, index, data) {
      console.log('onSelect: ', isChecked, index, data)
      console.log('Checked Data:', this.$refs.table.getCheckedRowDatas(true))
    },
    onSelectionChange (checkedDatas, checkedIndexs, checkedNum) {
      //this.params.edit = {};
      var editableRows = [];
      checkedIndexs.forEach(checkedIndexsFunction)

      function checkedIndexsFunction(element) {
        if(element != 0) {
          editableRows.push(element)
        }
      }

      var editCells = []
      var i = 0
      for(i = 0; i <= editableRows.length; i++) {
        var y = 0
        for(y = 0; y <= this.editableCols.length; y++) {
          editCells.push([editableRows[i], this.editableCols[y]])
        }
      }

      this.params.highlight = {cell: editCells};
      //this.params.edit = {row:editableRows, column: [2], cell: [[-1, -1]]};
      this.params.edit = {cell: editCells};
      console.log('edit:', editableRows, this.editableCols)

      console.log('onSelectionChange: ', checkedDatas, checkedIndexs, checkedNum)
    },
    changeStatus: function () {
      console.log("HAHAHA")

      this.$http.post('https://grid.cl-serv.de/index.php?action=LIST')
          .then(response => {
            return response.json()
          })
          .then(data => {
            //console.log(data.data)

            var result = [];

            result.push(Object.keys(data.data[0]))

            data.data.forEach(
                element => result.push( Object.values(element))
            );

            // editableCols

            var _myeditableCols = [];

            var i = 1;
            for(i=1; i <= Object.keys(data.data[0]).length; i++) {
              _myeditableCols.push(i);
            }

            this.editableCols = _myeditableCols;

            //console.log("_myeditableCols: ", _myeditableCols)
            //console.log(result)

            this.params.data = result;
            }
          )
    }
  },
  created: function() {
    // `this` points to the vm instance
    this.changeStatus()
    console.log('ChangeStatus')
  }
}
</script>