<template>
  <div id="app">
    <mTable/>
  </div>
</template>

<script>
import mTable from './components/mTable'

export default {
  name: 'App',
  components: {
    mTable
  }
}
</script>

<style scoped>

</style>
